import SearchBox from '../component/search-box/index.vue'
// import CascadeBox from './component/cascade-box/index.vue'
import TravelerMultipleSelector from 'yinzhilv-js-sdk/frontend/common-project/component/haolv/traveler-multiple-selector/2.2.8/index.vue';
import CascadeRadioBox from '../component/cascade-radio-box/index.vue'
import Pagination from '@/component/pagination/index.vue'
import delDialog from '../component/del-dialog/index.vue'
import resetPasswordDialog from '../component/reset-password-dialog/index.vue'
import consumer_web_staff_pageList from '@/lib/data-service/haolv-default/consumer_web_staff_pageList'
import consumer_department_getDepartmentList from '@/lib/data-service/haolv-default/consumer_department_getDepartmentList' // 获取部门list
import consumer_role_roleAll from '@/lib/data-service/haolv-default/consumer_role_roleAll' // 获取角色list
import consumer_web_staff_delete from '@/lib/data-service/haolv-default/consumer_web_staff_delete'
import consumer_web_staff_detail from '@/lib/data-service/haolv-default/consumer_web_staff_detail'
import consumer_web_staff_addOrUpdate from '@/lib/data-service/haolv-default/consumer_web_staff_addOrUpdate'
import getCompanyInfo from '@/lib/data-service/haolv-default/consumer_web_company_getCompanyInfo' // 获取公司信息
import consumer_tUsRank_rankList from '@/lib/data-service/haolv-default/consumer_t-us-rank_rankList' // 获取职级list
import consumer_web_staff_reSetPassword from '@/lib/data-service/haolv-default/consumer_web_staff_reSetPassword' // 重置员工密码
import consumer_department_getDepartments from '@/lib/data-service/haolv-default/consumer_department_getDepartments'
import consumer_web_city_areaLinkage from '@/lib/data-service/haolv-default/consumer_web_city_areaLinkage'
import consumer_web_position_getPositionAll from '@/lib/data-service/haolv-default/consumer_web_position_getPositionAll' // 获取职位列表
import consumer_country_queryCountryTwoWord from '@/lib/data-service/haolv-default/consumer_country_queryCountryTwoWord'
import consumer_admin_flight_generate_certificateType from '@/lib/data-service/haolv-default/consumer_admin_flight_generate_certificateType'
import consumer_admin_company_query from '@/lib/data-service/haolv-default/consumer_admin_company_query'
import {awaitWrap} from '../common/unit'
import moment from "moment";
export default {
    data () {
        const validatePhone = (rule, value, callback) => {
            const regex = /^1[3456789]\d{9}$/;
            if (regex.test(value) && value.toString().length === 11) {
                callback()
            } else {
                callback(new Error('不符合手机号码格式'))
            }
        }
        const validateCard = (rule, value, callback) => {
            let index = 0
            let hasItem = 0
            this.newParams.certificates.forEach((value1, nIndex) => {
                if (value1.certificateValue === value) {
                    index = nIndex
                    hasItem++
                }
            })
            if (hasItem > 1) {
                callback(new Error('有重复项'))
            } else {
                if (this.newParams.certificates[index].certificateType === 1) {
                    const regIdCard = /(^\d{15}$)|(^\d{18}$)|(^\d{17}(\d|X|x)$)/;
                    if (!regIdCard.test(value)) {
                        callback(new Error('身份证号填写有误'))
                    } else {
                        callback()
                    }
                } else {
                    callback()
                }
            }
        }
        const validateStaffName = (rule, value, callback) => {
            const regExp = /^[\u0391-\uFFE5A-Za-z]+$/
            if (regExp.test(value)) {
                callback()
            } else {
                callback('只能输入中文、英文')
            }
        }
        return {
            specialFocus: false,
            checked: false,
            travelUserNameList: [],
            travelUserList: [],

            loading: false,
            params: {
                companyId: null,   // 公司id，前端不传
                deptIds: null,   // 部门ids
                keyword: '',   // keyword

                currentPage: 1,   // 查询页码
                pageSize: 10,   // 查询条数
            },
            total: 0,   //总条数

            cascadeList: [],
            treeDataSource: [], // 全部部门list
            activeList: [], // 筛选出来部门list

            tableData: [],
            dialogVisible: false,
            dialogType: 'add', // 新增add 编辑edit
            dialogStatus: {
                add: '新增员工',
                edit: '编辑员工'
            },
            staffNameList: [],
            newParams: {
                birthDate: '', // 生日
                certificates: [
                    /*{
                        certificateType: 1, // 1.身份证，2.护照，3.港澳通行证，4.回乡证，5.台胞证，6.军官证，7.香港身份证，8.台湾通行证，9.户口本，10.其它
                        certificateValue: '', // 证件号
                        gmtCreate: '',
                        gmtModified: '',
                        id: '',
                        status: '', // 证件状态：1.正常，2.过期，3.删除
                        issCountryCode: '', // 发证国家
                        passengerExpireDate: '', // 证件有效期
                    }*/
                ], // 证件合集
                nationality: '', // 国籍
                phone: '',
                rankId: '', // 职级id
                staffName: '', // 姓名
                familyName: '', // 姓
                givenName: '', // 名
                staffSex: 1, // 性别：1.男，2.女
                deptId: '', // 部门id
                deptArr: [],
                email: '',
                englishName: '', // 英文姓名
                enSurname: '', // 英文性别
                enName: '', // 英文名字
                roleIds: [], // 角色id集合
                staffId: '', // 员工id,修改必填
                positionId: '', // 职位
                specialSms: 1, // 特别关注机票出改退航变短信：0-关闭，1-启用(默认)
                specialFocusUserIds: [],   // 特别关注人员id集合
            },
            rule: {
                phone: [
                    { required: true, message: '请输入手机号码', trigger: 'blur' },
                    { validator: validatePhone, trigger: 'blur' }
                ],
                staffName: [
                    { required: true, message: '请输入姓名', trigger: 'blur' },
                    { validator: this.validateStaffName, trigger: 'blur'}
                ],
                familyName: [
                    { required: true, message: '请输入姓', trigger: 'blur' },
                    { validator: this.validateFamilyName, trigger: 'blur'}
                ],
                givenName: [
                    { required: true, message: '请输入名', trigger: 'blur' },
                ],
                englishName: [],
                certificateValue: [],
                birthDate: [
                    { required: true, message: '请输入生日日期', trigger: 'blur' },
                ],
                nationality: [
                    { required: true, message: '请选择国籍', trigger: 'blur' },
                ],
                staffSex: [
                    { required: true, message: '请选择性别', trigger: 'change' },
                ],
                email: [
                    { type: 'email', message: '请输入正确的邮箱地址', trigger: 'blur' }
                ],
                rankId: [
                    { required: true, message: '请选择职级', trigger: 'change' },
                ],
                roleIds: [],
            },
            addLonLatRules: [
                {required: true, message: '请输入证件信息', trigger: 'blur'},
                {validator: validateCard, trigger: 'blur'},
            ],
            cardTypeList: [
                /*{value: 1, label: '身份证', disabled: false},
                {value: 2, label: '护照', disabled: false},
                {value: 4, label: '回乡证', disabled: false},
                {value: 5, label: '台胞证', disabled: false},
                {value: 6, label: '军官证', disabled: false},
                {value: 7, label: '香港身份证', disabled: false},
                {value: 11, label: '澳门身份证', disabled: false},
                {value: 10, label: '其它', disabled: false}*/
                ],
            delParams: {
                id: '',
                status: 3,
                userId: ''
            },
            dialogDelVisible: false,
            resetPasswordParams: {
                id: ''
            },
            dialogResetVisible: false,
            rankList: [],
            roleList: [],
            companyInfo: {},
            nationalityList: [],
            positionList: [],
            structureList: [],
            countryList: [],

            enableAutoInit: false,
            staffIdList: [],
        }
    },
    components: {
        SearchBox,
        CascadeRadioBox,
        Pagination,
        delDialog,
        resetPasswordDialog,
        TravelerMultipleSelector
    },
    created () {},
    mounted () {
        this.getCertificateType();
    },
    async activated () {
        await this.getDeptList()
        this.treeDataSource = this.initTreeData(this.cascadeList)

        await this.getCompany()
        this.getStaffList()
        this.getRankList()
        this.getAreaLine()
        this.getRoleList()
        this.getPositionList()
        this.getStructureList()
        this.queryCountryTwoWord();
    },
    deactivated() {},
    destroyed () {},
    watch: {
        activeList(newVal) {

        },
        staffIdList(val) {
            this.$refs.travelerMultipleSelector.get_selected_entity_list_promise({
                id_list: val,
            }).then((res) => {
                this.travelUserList = res.entity_list;
                this.newParams.specialFocusUserIds = [];
                this.travelUserNameList = [];
                for (let i = 0;i < this.travelUserList.length;i++) {
                    this.newParams.specialFocusUserIds.push(this.travelUserList[i].userId);
                    this.travelUserNameList.push(this.travelUserList[i].name);
                }
                if (this.travelUserList.length > 0) {
                    this.newParams.specialSms = 1;
                }
            });
        }
    },
    computed: {},
    filters: {},
    methods: {
        selectFocus() {
            this.$refs.travelerMultipleSelector.show();
            this.staffIdList = this.newParams.specialFocusUserIds;
        },
        clearSpecialFocus() {
            this.staffIdList = [];
            this.newParams.specialFocusUserIds = [];
            this.newParams.specialFocusList = [];
        },
        focusSpecialFocus() {
            this.$refs.specialFocus.blur();
        },
        getCertificateType() {
            consumer_admin_flight_generate_certificateType().then(res => {
                let certificateTypes = res.datas;
                certificateTypes.forEach(value => {
                    value.value = value.code;
                    value.label = value.msg;
                    value.disabled = false;
                })
                this.cardTypeList = certificateTypes
            })
        },
        queryCountryTwoWord() {
            consumer_country_queryCountryTwoWord().then(res => {
                this.countryList = res.datas
            })
        },
        async getDeptList() {
            let [err, res] = await awaitWrap(consumer_department_getDepartmentList())
            if (err) {
                this.cascadeList = []
                return
            }
            this.cascadeList = res.datas.departmentListResults ? res.datas.departmentListResults : []
        },
        getRoleList() {
            consumer_role_roleAll().then(res => {
                this.roleList = res.datas;
            })
        },
        getRankList() {
            consumer_tUsRank_rankList({companyId: this.companyInfo.id}).then(res => {
                this.rankList = res.datas
            })
        },
        getPositionList() {
            consumer_web_position_getPositionAll({companyId: this.companyInfo.id}).then(res => {
                this.positionList = res.datas
            })
        },
        async getCompany() {
            await this.$store.state.userInfoDefer.promise;
            let res = await getCompanyInfo();
            this.companyInfo = res.datas ? res.datas : {};
            this.companyInfo.companyId = this.$store.state.userInfo.companyId;
            consumer_admin_company_query({
                companyId: this.companyInfo.companyId,
                bizType: 7,
            }).then(res => {
                this.specialFocus = res.datas.companyExpendVo.specialFocus;
            });
        },
        getAreaLine() {
            consumer_web_city_areaLinkage({levelType: 1}).then(res => {
                this.nationalityList = res.datas
            })
        },
        getStructureList() {
            consumer_department_getDepartments({companyId: this.companyInfo.companyId}).then(res => {
                this.structureList = res.datas.departmentVos
            })
        },
        initTreeData(list) {
            // 这里一定要转化，要不然他们的值监听不到变化
            let tempData = JSON.parse(JSON.stringify(list))
            let reduceDataFunc = (data, level) => {
                data.map((m, i) => {
                    m.isExpand = false // 是否展开
                    m.check = false
                    m.indeterminate = false
                    // m.children = m.children || []
                    m.level = level
                    m.bLeft = level * 15
                    m.label = m.name
                    m.value = m.deptId
                    m.children = m.childrenList
                    delete m.childrenList
                    if (m.children && m.children.length > 0) {
                        reduceDataFunc(m.children, level + 1)
                    }
                })
            }
            reduceDataFunc(tempData, 1)
            return tempData
        },
        handleAddWorker() {
            this.reactNewParams()
            this.reactCardTypeList()
            // 避免选择相同的证件类型
            /*this.cardTypeList.forEach(value => {
                if (value.value === 1) {
                    value.disabled = true
                }
            })*/
            this.newParams.specialSms = 1;
            this.dialogType = 'add';
            this.dialogVisible = true;
            this.travelUserList = [];
            this.newParams.specialFocusUserIds = [];
            this.travelUserNameList = [];
            this.$nextTick(() => {
                this.$refs.travelerMultipleSelector.init({
                    situation: `102`,
                });
                this.$refs['form'].clearValidate();
                let box = document.querySelector('#user-dialog .el-dialog__body');
                box.scrollTop = 0;
            })
        },
        changeIdCard(val) {
            // 避免选择相同的证件类型
            let activeArr = []
            this.newParams.certificates.forEach(value => {
                activeArr.push(value.certificateType)
            })
            this.cardTypeList.forEach(value => {
                if (activeArr.indexOf(value.value) > -1) {
                    value.disabled = true
                } else {
                    value.disabled = false
                }
            })

            if (val === 1) {
                this.newParams.nationality = 'CN'
            }
        },
        toSelect(val) {
            this.search()
        },
        delSelect(val) {
            this.search()
        },
        changeKey(val) {
            this.search()
        },
        submitAdd() {
            this.$refs['form'].validate((valid, invalidFields) => {
                if (valid) {
                    this.newParams.deptId = this.newParams.deptArr[this.newParams.deptArr.length - 1]
                    /*this.loading = this.$loading({
                        lock: true,
                        text: 'Loading',
                        spinner: 'el-icon-loading',
                    })*/
                    this.loading = true
                    let newParams = Object.assign({}, this.newParams)
                    newParams.username = newParams.phone;
                    newParams.staffName = newParams.familyName + newParams.givenName;
                    consumer_web_staff_addOrUpdate(newParams).then(res => {
                        // this.loading.close()
                        this.loading = false
                        this.$message({
                            message: '新增成功',
                            type: 'success'
                        });
                        this.dialogVisible = false
                        this.getStaffList()
                    }).catch(() => {
                        // this.loading.close()
                        this.loading = false
                    })
                } else {
                    this.$nextTick(() => {
                        let isError = document.getElementsByClassName('is-error')
                        isError[0].scrollIntoView({
                            block: 'center',
                            behavior: 'smooth'
                        })
                    })
                }
            })

        },
        getStaffList() {
            /*this.loading = this.$loading({
                lock: true,
                text: 'Loading',
                spinner: 'el-icon-loading',
            })*/
            this.loading = true
            if (this.companyInfo) {
                this.params.companyId = this.companyInfo.id
            }
            consumer_web_staff_pageList(this.params).then(res => {
                // this.loading.close()
                this.loading = false
                if (res.code === '000000') {
                    this.tableData = res.datas.list
                    this.total = res.datas.totalCount
                } else {
                    this.tableData = []
                }
            }).catch(() => {
                // this.loading.close()
                this.loading = false
                this.tableData = []
            })
        },
        search() {
            if (this.activeList.length === 0) {
                this.params.deptIds = null
            } else {
                let deptIds = []
                this.activeList.forEach(value => {
                    deptIds.push(value.deptId)
                })
                this.params.deptIds = deptIds
            }
            this.params.currentPage = 1
            this.getStaffList()
        },
        reactNewUserParams() {
            this.newParams = {
                birthDate: '', // 生日
                certificates: [{
                    certificateType: 1, // 1.身份证，2.护照，3.港澳通行证，4.回乡证，5.台胞证，6.军官证，7.香港身份证，8.台湾通行证，9.户口本，10.其它
                    certificateValue: '', // 证件号
                    gmtCreate: '',
                    gmtModified: '',
                    id: '',
                    status: '', // 证件状态：1.正常，2.过期，3.删除
                    issCountryCode: '', // 发证国家
                    passengerExpireDate: '', // 证件有效期
                }], // 证件合集
                nationality: '', // 国籍
                phone: '',
                rankId: '', // 职级id
                staffName: '', // 姓名
                familyName: '', // 姓
                givenName: '', // 名
                staffSex: 1, // 性别：1.男，2.女
                deptId: '', // 部门id
                deptArr: [],
                email: '',
                englishName: '', // 英文姓名
                enSurname: '', // 英文性别
                enName: '', // 英文名字
                roleIds: [], // 角色id集合
                staffId: '', // 员工id,修改必填
                positionId: '', // 职位
                specialFocusUserIds: [],   // 特别关注用户ID
                specialSms: 1,   // 特别关注是否发短信
            }
        },
        handleEdit(val) {
            consumer_web_staff_detail({id: val.staffId}).then(res => {
                let userData = res.datas[0]
                this.reactNewUserParams();
                this.newParams = Object.assign({}, this.newParams, userData)
                if (this.newParams.englishName) {
                    let englishNameArr = this.newParams.englishName.split('.')
                    this.newParams.enSurname = englishNameArr[0] // 英文性别
                    this.newParams.enName = englishNameArr[1] // 英文名字
                }
                // this.newParams.nationality = parseInt(this.newParams.nationality)
                this.newParams.deptArr = this.getDeptArr(this.newParams.deptId)
                let roleIds = []
                this.newParams.roleList.forEach(val => {
                    roleIds.push(val.id)
                })
                this.newParams.roleIds = roleIds // 角色列表
                this.newParams.certificates = this.newParams.certificateList // 身份文件列表
                this.staffNameList = [];
                this.newParams.specialFocusUserIds = [];
                if (this.newParams.specialFocusList) {
                    for(let i = 0;i < this.newParams.specialFocusList.length;i++){
                        if (this.staffNameList.indexOf(this.newParams.specialFocusList[i].specialFocusName) == -1) {
                            this.staffNameList.push(this.newParams.specialFocusList[i].specialFocusName);
                        }
                        if (this.newParams.specialFocusUserIds.indexOf(this.newParams.specialFocusList[i].specialFocusId) == -1) {
                            this.newParams.specialFocusUserIds.push(this.newParams.specialFocusList[i].specialFocusId);
                        }
                    }
                }
                /*if (this.newParams.certificates.length === 0) {
                    this.newParams.certificates.push({
                        certificateType: 1, // 1.身份证，2.护照，3.港澳通行证，4.回乡证，5.台胞证，6.军官证，7.香港身份证，8.台湾通行证，9.户口本，10.其它, 11 澳门通行证
                        certificateValue: '', // 证件号
                        gmtCreate: '',
                        gmtModified: '',
                        id: '',
                        status: '', // 证件状态：1.正常，2.过期，3.删除
                        issCountryCode: '', // 发证国家
                        passengerExpireDate: '', // 证件有效期
                    })
                }*/
                this.reactCardTypeList();
                this.newParams.specialFocusUserIds = [];
                this.travelUserNameList = [];
                if (this.newParams.specialFocusList) {
                    for (let i = 0;i < this.newParams.specialFocusList.length;i++) {
                        this.newParams.specialFocusUserIds.push(this.newParams.specialFocusList[i].specialFocusId);
                        this.travelUserNameList.push(this.newParams.specialFocusList[i].specialFocusName);
                    }
                }
                this.newParams.certificates.forEach(value => {
                    this.cardTypeList.forEach(value1 => {
                        if (value.certificateType === value1.value) {
                            value1.disabled = true
                        }
                    })

                });
                this.dialogType = 'edit';
                this.dialogVisible = true;
                this.travelUserList = [];
                this.$nextTick(() => {
                    this.$refs.travelerMultipleSelector.init({
                        situation: `102`,
                    });
                    this.$refs['form'].clearValidate();
                    let box = document.querySelector('#user-dialog .el-dialog__body');
                    box.scrollTop = 0;
                })
            })
        },
        getDeptArr(val) {
            let deptArr = []
            let _this = this
            const getDept = (value) => {
                for (let i = 0, l = this.structureList.length; i < l; i++) {
                    if (value === this.structureList[i].id) {
                        deptArr.unshift(value)
                        if (this.structureList[i].parentId !== 0) {
                            getDept(this.structureList[i].parentId)
                        }
                    }
                }
            }
            getDept(val)
            return deptArr
        },
        submitEdit() {
            this.$refs['form'].validate((valid) => {
                if (valid) {
                    this.newParams.deptId = this.newParams.deptArr[this.newParams.deptArr.length - 1]
                    /*this.loading = this.$loading({
                        lock: true,
                        text: 'Loading',
                        spinner: 'el-icon-loading',
                    })*/
                    this.loading = true
                    let newParams = Object.assign({}, this.newParams)
                    newParams.username = newParams.userName;
                    newParams.staffName = newParams.familyName + newParams.givenName;
                    newParams.specialSms = newParams.specialSms;
                    consumer_web_staff_addOrUpdate(newParams).then(res => {
                        // this.loading.close()
                        this.loading = false
                        this.$message({
                            message: '保存成功',
                            type: 'success'
                        });
                        this.dialogVisible = false
                        this.getStaffList()
                    }).catch(() => {
                        // this.loading.close()
                        this.loading = false
                    })
                } else {
                    this.$nextTick(() => {
                        let isError = document.getElementsByClassName('is-error')
                        isError[0].scrollIntoView({
                            block: 'center',
                            behavior: 'smooth'
                        })
                    })
                }
            })

        },
        handleReset(val) { // 重置密码
            this.resetPasswordParams.id = val.userId;
            this.dialogResetVisible = true
        },
        submitReset() {
            this.loading = true;
            consumer_web_staff_reSetPassword(this.resetPasswordParams).then(res => {
                this.loading = false;
                this.$message({
                    message: '重置密码成功',
                    type: 'success'
                });
                this.dialogResetVisible = false;
                if (this.tableData.length <= 1 && this.params.currentPage !== 1) {
                    this.params.currentPage--
                }
                this.getStaffList()
            }).catch(() => {
                this.loading = false
            })
        },
        handleDel(val) {
            this.delParams = {
                id: val.staffId,
                status: 3,
                userId: val.userId
            }
            this.dialogDelVisible = true
        },
        submitDel() {
            this.loading = true
            consumer_web_staff_delete(this.delParams).then(res => {
                // this.loading.close()
                this.loading = false
                this.$message({
                    message: '成功删除',
                    type: 'success'
                });
                this.dialogDelVisible = false
                if (this.tableData.length <= 1 && this.params.currentPage !== 1) {
                    this.params.currentPage--
                }
                this.getStaffList()
            }).catch(() => {
                // this.loading.close()
                this.loading = false
            })
        },
        reactNewParams() {
            let deptId = null;
            this.treeDataSource.forEach(item =>{
                if(item.deptName === '默认部门') {
                    deptId = item.deptId;
                }
            })
            this.newParams = {
                birthDate: '', // 生日
                certificates: [
                    /*{
                        certificateType: 1, // 1.身份证，2.护照，3.港澳通行证，4.回乡证，5.台胞证，6.军官证，7.香港身份证，8.台湾通行证，9.户口本，10.其它, 11 澳门通行证
                        certificateValue: '', // 证件号
                        gmtCreate: '',
                        gmtModified: '',
                        id: '',
                        status: '', // 证件状态：1.正常，2.过期，3.删除
                        issCountryCode: 'CN', // 发证国家
                        passengerExpireDate: '', // 证件有效期
                    }*/
                ], // 证件合集
                nationality: 'CN', // 国籍
                phone: '',
                rankId: '', // 职级id
                staffName: '', // 姓名
                staffSex: 1, // 性别：1.男，2.女
                deptId: '', // 部门id
                deptArr: deptId?[deptId]:[],
                email: '',
                englishName: '', // 英文姓名
                enSurname: '', // 英文性别
                enName: '', // 英文名字
                roleIds: [], // 角色id集合
                staffId: '', // 员工id,修改必填
                positionId: '', // 职位
                specialFocusUserIds: [],   // 特别关注用户ID
                specialSms: 1,   // 特别关注是否发短信
            }
        },
        change_englishName(key) {
            const certificateForm = this.newParams;
            let str = certificateForm[key];
            certificateForm[key] = str.replace(/[^a-z\_\-\.]/g, "");
        },
        changeEnSurname(val) {
            this.newParams.englishName = val + '.' + this.newParams.enName
        },
        changeEnName(val) {
            this.newParams.englishName = this.newParams.enSurname + '.' + val
        },
        removeCertificatesItem(index, val) {
            // 避免选择相同的证件类型
            this.cardTypeList.forEach(value => {
                if (value.value === val.certificateType) {
                    value.disabled = false
                }
            })
            this.newParams.certificates.splice(index, 1)
        },
        addCertificatesItem() {
            // if (this.newParams.certificates.length >= 3) {
            //     return
            // }
            // 避免选择相同的证件类型
            let type = ''
            for(let i = 0, l = this.cardTypeList.length; i < l; i++) {
                if (this.cardTypeList[i].disabled === false) {
                    type = this.cardTypeList[i].value
                    this.cardTypeList[i].disabled = true
                    break
                }
            }
            this.newParams.certificates.push({
                certificateType: type, // 1.身份证，2.护照，3.港澳通行证，4.回乡证，5.台胞证，6.军官证，7.香港身份证，8.台湾通行证，9.户口本，10.其它, 11 澳门通行证
                certificateValue: '', // 证件号
                gmtCreate: '',
                gmtModified: '',
                id: '',
                status: '', // 证件状态：1.正常，2.过期，3.删除
                issCountryCode: 'CN', // 发证国家
                passengerExpireDate: '', // 证件有效期
            })
        },
        getIDCardDate(index, val) {
            let certificateType = this.newParams.certificates[index].certificateType
            if (certificateType !== 1) {
                return
            }
            const regIdCard = /(^\d{15}$)|(^\d{18}$)|(^\d{17}(\d|X|x)$)/;
            if (regIdCard.test(val) && val.length === 18) {
                let birthDay = this.getBirth(val);
                let sex = this.getGenderByIdNumber(val);
                this.newParams.birthDate = birthDay;
                this.newParams.staffSex = sex;
            }
        },
        getGenderByIdNumber(idNumber) {
            if (idNumber) {
                let genderCode; // 性别代码
                if (idNumber.length == 18) { // 二代身份证号码长度为18位（第17位为性别代码）
                    genderCode = idNumber.charAt(16);
                } else if (idNumber.length == 15) { // 一代身份证号码长度为15位（第15位为性别代码）
                    genderCode = idNumber.charAt(14);
                }
                if (genderCode && !isNaN(genderCode)) {
                    // 两代身份证号码的性别代码都为男奇女偶
                    if (parseInt(genderCode) % 2 == 0) {
                        return 2;
                    }
                    return 1;
                }
            }
        },
        getBirth(idCard) {
            let birthday = '';
            if (idCard != null && idCard !== '') {
                if (idCard.length === 15) {
                    birthday = '19' + idCard.slice(6,12);
                } else if (idCard.length === 18) {
                    birthday = idCard.slice(6,14);
                }
                birthday = birthday.replace(/(.{4})(.{2})/,'$1-$2-');
                //通过正则表达式来指定输出格式为:1990-01-01
            }
            // 判断输入的生日号码是否为正常的日期
            if (isNaN(new Date(birthday).getTime())) {
                birthday = ''
            }
            console.log(birthday)
            return birthday;
        },
        reactCardTypeList() {
            this.cardTypeList.forEach(value => {
                value.disabled = false
            })
        },
        cardExpireDateDisabledDate(val) {
            const now = moment(val).format('YYYY-MM-DD')
            const nowTime = moment(now + ' 00:00:00').valueOf()
            const today = moment().format('YYYY-MM-DD')
            const todayTime = moment(today + ' 00:00:00').valueOf();
            return nowTime < todayTime
        },
        // 检验中文名
        validateStaffName(rule, value, callback) {
            let hasMustChinaCertificate = false;
            this.newParams.certificates.forEach(value1 => {
                if ([1, 12].indexOf(value1.certificateType) > -1) {
                    hasMustChinaCertificate = true;
                }
            })

            const regExp = hasMustChinaCertificate ? /^[\u4e00-\u9fa5]+$/ : /^[\u0391-\uFFE5A-Za-z]+$/
            if (regExp.test(value)) {
                callback()
            } else {
                callback(hasMustChinaCertificate ? '只能输入中文' : '只能输入中文、英文')
            }
        },
        validateFamilyName(rule, value, callback) {
            let hasMustChinaCertificate = false;
            this.newParams.certificates.forEach(value1 => {
                if ([1, 12].indexOf(value1.certificateType) > -1) {
                    hasMustChinaCertificate = true;
                }
            })

            const regExp = hasMustChinaCertificate ? /^[\u4e00-\u9fa5]+$/ : /^[\u0391-\uFFE5A-Za-z]+$/
            if (regExp.test(value)) {
                callback()
            } else {
                callback(hasMustChinaCertificate ? '只能输入中文' : '只能输入中文、英文')
            }
        }
    }
}
/*
* export default {
  data () {
    return {}
  },
  components: {},
  created () {},
  mounted () {},
  activated () {
  },
  deactivated() {},
  destroyed () {},
  watch: {},
  computed: {},
  filters: {},
  methods: {
  }
}
*/
